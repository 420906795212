<template>
  <div>
    <b-card class="profile-header mb-2" :img-src="coverImg" img-top img-style="max-height:200px" alt="cover photo"
      body-class="p-0">
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img :src="headerData.avatar" class="w-100" rounded fluid alt="profile photo" />
          </div>
          <!-- profile title -->
          <!-- <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.first_name }}
          </h2>
          
          <p class="text-white d-none d-md-block">
            {{ headerData.email }}
          </p>
        </div> -->
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar toggleable="md" type="light" class="mb-0">
          <!-- toggle button -->
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>
          <!--/ toggle button -->

          <!-- collapse -->
          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <b-tabs class="community-tabs">
                    <b-tab :active="channel.value == filterByType" @click="AddFilter(channel.value)"
                      v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value">
                      <template #title>
                        <span>{{ channel.label }}
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge[channel.value]"
                            v-b-modal="'unseen-' + channel.value" v-b-tooltip.hover.v-default
                            :title="unseenBadge[channel.value] + ' unseen post(s)'">
                            {{ unseenBadge[channel.value] }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('post')" v-if="!$route.params.id && userData.application_mode == 'company'">
                      <template #title>
                        <span>Feeds
                          <b-badge variant="primary" v-b-modal="'unseen-feeds'"
                            v-if="!$route.params.id && unseenBadge.feeds" v-b-tooltip.hover.v-default
                            :title="unseenBadge.feeds + ' unseen post(s)'">
                            {{ unseenBadge.feeds }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('event')">
                      <template #title>
                        <span>Events
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.events"
                            v-b-modal="'unseen-events'" v-b-tooltip.hover.v-default
                            :title="unseenBadge.events + ' unseen event(s)'">
                            {{ unseenBadge.events }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('poll')">
                      <template #title>
                        <span>Polls
                          <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.polls"
                            v-b-tooltip.hover.v-default :title="unseenBadge.polls + ' unseen poll(s)'">
                            {{ unseenBadge.polls }}
                          </b-badge>
                        </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('saved_posts')"
                      v-if="$route.params.id && $route.params.id == userData.hashid">
                      <template #title>
                        <span>Saved Posts </span>
                      </template>
                    </b-tab>
                    <b-tab @click="AddFilter('review_posts')"
                      v-if="$can('Zircly Admin') && userData.need_approval == 'true'">
                      <template #title>
                        <span>Review Posts </span>
                      </template>
                    </b-tab>
                  </b-tabs>
                  <div class="d-flex align-items-center ref-info">
                    <div class="mr-2">
                      <b-link class="text-body">
                        <feather-icon icon="InfoIcon" size="18" class="mr-1" v-b-tooltip.hover.v-default
                          title="Channels Descriptions" v-b-modal.channel-descriptions-modal />
                      </b-link>
                      <b-link class="text-body ml-auto">
                        <feather-icon @click="refreshListData()" icon="RefreshCcwIcon" size="18" class="mr-35"
                          v-b-tooltip.hover.v-default title="Refresh" />
                      </b-link>
                    </div>
                    <b-button v-b-modal.modal-1 pill v-show="!userData.last_swipe_type ||
                      userData.last_swipe_type === 'Out'
                      " variant="outline-primary" class="mr-1 login-btn"
                      v-if="$can('swipe_access') && userData.hashid == headerData.hashid">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#38B22D"
                            stroke="#38B22D" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check In </span>
                    </b-button>
                    <b-button v-b-modal.modal-2 v-show="userData.last_swipe_type === 'In'
                      " variant="outline-danger" class="mr-1 logout-btn" pill
                      v-if="$can('swipe_access') && userData.hashid == headerData.hashid">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#E82E2E"
                            stroke="#E82E2E" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check Out </span>
                    </b-button>
                  </div>
                </div>
                <b-modal id="modal-1" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :hide-header="true"
                  :hide-footer="true" centered>
                  <div v-if="'No' in userData.previous_day_attendance_status">
                    <p class="my-1 text-center mobile-text">
                      Regularization Required for last working day -
                      {{
                        userData.previous_day_attendance_status.No | formatDate
                      }}, Please Apply for Regularization for last working day
                    </p>
                  </div>
                  <form ref="form" @submit.stop.prevent="handleOk">
                    <b-form-group label="" label-for="name-input" class="">
                      <p class="h2 my-1 text-center text-dark">
                        How are you feeling today?
                      </p>
                      <br />
                      <b-form-radio-group buttons class="mx-n1 my-2 feeling-smiley" size="sm" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="light">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" class="mr-1" />
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox value="6" v-model="swipeData.user_feeling" class="d-flex align-items-center">
                        Prefer not to say
                      </b-form-checkbox>
                    </b-form-group>
                    <div class="text-center">
                      <b-button type="submit" variant="outline-primary" v-if="swipeData.user_feeling">
                        Submit
                      </b-button>
                    </div>
                  </form>
                </b-modal>
                <b-modal id="modal-2" size="lg" @show="resetModal" @hidden="resetModal" @ok="checkout" :hide-header="true"
                  :hide-footer="true" centered>
                  <form ref="form" @submit.stop.prevent="checkout">
                    <b-form-group label="" label-for="name-input" class="">
                      <p class="h2 my-1 text-center text-dark">
                        How you felt today?
                      </p>
                      <br />
                      <b-form-radio-group buttons class="mx-n1 my-2 feeling-smiley" size="sm" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="light">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 rounded-pill btn-light ml-1 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" />
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox value="6" v-model="swipeData.user_feeling" class="d-flex align-items-center">
                        Prefer not to say
                      </b-form-checkbox>
                    </b-form-group>
                    <div class="text-center">
                      <b-button type="submit" variant="outline-primary" v-if="swipeData.user_feeling">
                        Submit
                      </b-button>
                    </div>
                  </form>
                </b-modal>
                <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                v-b-modal.recent-swipes
                class="mr-1"
                pill
                v-if="$can('swipe_access') && userData.hashid == headerData.hashid"
                @click="getRecentSwipes"
              >
                Recent Swipes
              </b-button> -->
              </template>
              <!-- Recent Swipe Modal-->
              <b-modal id="recent-swipes" hide-footer size="lg" modal-class="modal-primary modal-swipes d-none" centered
                title="Recent Swipes">
                <b-list-group v-if="recentSwipes && recentSwipes.length" class="swipes-group">
                  <b-list-group-item class="d-flex align-items-center swipe-mobile" v-for="swipe in recentSwipes"
                    :key="swipe.hashid">
                    <b>Date:</b><span>{{ swipe.date | formatDate }}</span>, <b>Time:</b>
                    <span>{{ swipe.time | formatTime }}</span>,<b>Swipe type:</b>
                    <span>{{ swipe.type }}</span>
                    <b-img v-if="swipe.user_feeling != 6" v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }" fluid
                      :src="mood_options[swipe.user_feeling]" alt="mood" />
                  </b-list-group-item>
                </b-list-group>
                <b-card-text v-else-if="recentSwipes && recentSwipes.length == 0">
                  <div class="item error text-center my-2 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No Records Found!</p>
                  </div>
                </b-card-text>
                <b-card-text v-else="recentSwipes == null">
                  <div class="d-flex align-items-center">
                    <strong>Loading...</strong>
                    <b-spinner class="ml-auto" variant="primary" />
                  </div>
                </b-card-text>
              </b-modal>
            </b-tabs>
          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>
    <PostView :item-id.sync="postId" :sidebar-view-active.sync="sidebarViewActive"
      :employeeListOptions.sync="employeeListOptions" :itemData.sync="postData" />
    <PostEdit :item-id.sync="postId" :sidebar-edit-active.sync="sidebarEditActive"
      :employeeListOptions.sync="employeeListOptions" :customFields.sync="customFields" :itemData.sync="postData" />
    <EventAdd :item-id.sync="postId" :sidebar-event-add-active.sync="sidebarEventAddActive"
      :employeeListOptions.sync="employeeListOptions" :customFields.sync="customFields" />
    <PollAdd :item-id.sync="postId" :sidebar-poll-add-active.sync="sidebarPollAddActive" />

    <div class="row px-2">
      <div class="col-lg-8 offset-0">

        <b-overlay :show="false" rounded="sm" :variant="skin">
          <div class="card col-xl-12 p-0">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <b-tabs class="d-none">
                  <b-tab :active="channel.value == filterByType" @click="AddFilter(channel.value)"
                    v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value">
                    <template #title>
                      <span>{{ channel.label }}
                        <b-badge variant="primary" v-if="!$route.params.id && unseenBadge[channel.value]"
                          v-b-modal="'unseen-' + channel.value" v-b-tooltip.hover.v-default
                          :title="unseenBadge[channel.value] + ' unseen post(s)'">
                          {{ unseenBadge[channel.value] }}
                        </b-badge>
                      </span>
                    </template>
                  </b-tab>

                  <!-- <b-tab active @click="AddFilter('community')">
              <template #title>
                <span
                  >Community
                  <b-badge
                    variant="primary"
                    v-if="unseenBadge.community"
                    v-b-modal="'unseen-community'"
                    v-b-tooltip.hover.v-default
                    :title="unseenBadge.community + ' unseen post(s)'"
                  >
                    {{ unseenBadge.community }}
                  </b-badge>
                </span>
              </template>
            </b-tab> -->
                  <b-tab @click="AddFilter('post')" v-if="!$route.params.id && userData.application_mode == 'company'">
                    <template #title>
                      <span>Feeds
                        <b-badge variant="primary" v-b-modal="'unseen-feeds'"
                          v-if="!$route.params.id && unseenBadge.feeds" v-b-tooltip.hover.v-default
                          :title="unseenBadge.feeds + ' unseen post(s)'">
                          {{ unseenBadge.feeds }}
                        </b-badge>
                      </span>
                    </template>
                  </b-tab>
                  <b-tab @click="AddFilter('event')">
                    <template #title>
                      <span>Events
                        <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.events"
                          v-b-modal="'unseen-events'" v-b-tooltip.hover.v-default
                          :title="unseenBadge.events + ' unseen event(s)'">
                          {{ unseenBadge.events }}
                        </b-badge>
                      </span>
                    </template>
                  </b-tab>
                  <b-tab @click="AddFilter('poll')">
                    <template #title>
                      <span>Polls
                        <b-badge variant="primary" v-if="!$route.params.id && unseenBadge.polls"
                          v-b-tooltip.hover.v-default :title="unseenBadge.polls + ' unseen poll(s)'">
                          {{ unseenBadge.polls }}
                        </b-badge>
                      </span>
                    </template>
                  </b-tab>
                  <!-- <b-tab @click="AddFilter('feedback_box')">
              <template #title>
                <span
                  >Feedback Box
                  <b-badge
                    variant="primary"
                    v-if="unseenBadge.feedback"
                    v-b-tooltip.hover.v-default
                    :title="unseenBadge.feedback + ' unseen post(s)'"
                  >
                    {{ unseenBadge.feedback }}
                  </b-badge>
                </span>
              </template>
            </b-tab> -->
                  <b-tab @click="AddFilter('saved_posts')" v-if="$route.params.id && $route.params.id == userData.hashid">
                    <template #title>
                      <span>Saved Posts </span>
                    </template>
                  </b-tab>
                  <b-tab @click="AddFilter('review_posts')"
                    v-if="$can('Zircly Admin') && userData.need_approval == 'true'">
                    <template #title>
                      <span>Review Posts </span>
                    </template>
                  </b-tab>
                  <b-tab v-if="onGoingProgram.hashid" @click="AddFilter('secret_santa_post')">
                    <template #title>
                      <span style="margin-top: -3px;">
                        Secret Santa Feeds
                        <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-default
                          title="Secret Santa Program" :to="{ name: 'secret-santa' }" :src="require('@/assets/images/secret-santa/santa-claus.png')
                            " v-if="$can('secret_santa_access') || $can('Zircly Admin')" size="sm" />
                      </span>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
              <!-- channel descriptions modal -->
              <b-modal id="channel-descriptions-modal" ok-only ok-title="Accept" modal-class="modal-primary" centered
                size="lg" title="Channel Descriptions" hide-footer>
                <b-tabs class="mb-1">
                  <b-tab v-if="communityChannels.length" v-for="channel, index in communityChannels" :title="channel.label"
                    :active="index == 0">
                    <b-card-text v-if="channel.description" class="ml-1">
                      {{ channel.description }}
                    </b-card-text>
                    <b-card-text v-else class="ml-1">
                      This channel has no description.
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-modal>
              <div class="row" v-show="filterByType != 'event' && filterByType != 'poll'">
                <!-- <div :class="filterByType != 'post' ? 'col-sm-9' : 'col-sm-12'"> -->
                <div class="col-sm-9">
                  <div class="position-relative search-posthead">
                    <b-input-group class="input-group-merge mb-1 round">
                      <b-form-input v-b-modal.create-custompop class="d-inline-block" placeholder="Whats on your mind?" />
                    </b-input-group>
                  </div>
                </div>
                <div class="col pl-0" v-if="!$route.params.id">
                  <b-button variant="outline-secondary" v-b-toggle.add-sidebar pill class="blog-btn-block w-100">
                    <span class="text-nowrap">Create Post</span>
                  </b-button>
                </div>
              </div>

              <div class="row" v-show="filterByType == 'event'">
                <div class="col-lg-5">
                  <div class="position-relative search-posthead">
                    <b-input-group class="input-group-merge mb-1 round">
                      <!-- <b-input-group-prepend is-text>
                    <feather-icon
                      icon="SearchIcon"
                      v-b-tooltip.hover.v-default
                      title="Search"
                    />
                  </b-input-group-prepend> -->
                      <b-form-input v-model="searchQueryEvent" class="d-inline-block" placeholder="Whats on your mind?"
                        v-on:keyup.enter="
                          bus.$emit('loadEvents', {
                            q: searchQueryEvent,
                            type: typeFilter,
                          })
                          " />
                      <b-input-group-append is-text v-if="searchQueryEvent.length > 0">
                        <feather-icon icon="XIcon" class="text-default mr-1 cursor-pointer" v-b-tooltip.hover.v-default
                          title="Clear Search" @click="
                            searchQueryEvent = '';
                          bus.$emit('loadEvents', {
                            q: searchQueryEvent,
                            type: typeFilter,
                          });
                          " />
                      </b-input-group-append>
                      <b-input-group-append is-text>
                        <b-button variant="primary" pill class="blog-btn-block mr-1 search-post"
                          v-b-tooltip.hover.v-default title="Search Event" @click="
                            bus.$emit('loadEvents', {
                              q: searchQueryEvent,
                              type: typeFilter,
                            })
                            ">
                          <feather-icon icon="SearchIcon" width="18" height="18" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
                <div class="col-lg-4">
                  <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="typeFilter"
                    :options="typeOptions" class="w-100" :reduce="(val) => val.value" placeholder="Select type.."
                    :clearable="false" />
                </div>
                <div class="col-lg-3" v-if="!$route.params.id">
                  <b-button variant="outline-secondary" v-b-toggle.add-event-sidebar pill class="blog-btn-block w-100">
                    <span class="text-nowrap">Create Event</span>
                  </b-button>
                </div>
              </div>
              <div class="row" v-show="filterByType == 'poll'">
                <div class="col-sm-9">
                  <div class="search-posthead">
                    <b-input-group v-show="filterByType == 'poll'" class="input-group-merge mb-1 round">
                      <!-- <b-input-group-prepend is-text>
                    <feather-icon
                      icon="SearchIcon"
                      v-b-tooltip.hover.v-default
                      title="Search"
                    />
                  </b-input-group-prepend> -->
                      <b-form-input v-model="searchQueryPoll" class="d-inline-block" placeholder="Whats on your mind?"
                        v-on:keyup.enter="bus.$emit('loadPolls', searchQueryPoll)" />
                      <b-input-group-append is-text v-if="searchQueryPoll.length > 0">
                        <feather-icon icon="XIcon" class="text-default mr-1 cursor-pointer" v-b-tooltip.hover.v-default
                          title="Clear Search" @click="
                            searchQueryPoll = '';
                          bus.$emit('loadPolls', searchQueryPoll);
                          " />
                      </b-input-group-append>
                      <b-input-group-append is-text>
                        <b-button variant="primary" pill class="blog-btn-block mr-1 search-post"
                          @click="bus.$emit('loadPolls', searchQueryPoll)" title="Search Poll"
                          v-b-tooltip.hover.v-default>
                          <feather-icon icon="SearchIcon" width="18" height="18" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
                <div class="col pl-0" v-if="!$route.params.id">
                  <b-button class="blog-btn-block w-100" variant="outline-secondary" v-b-toggle.add-poll-sidebar pill>
                    <span class="text-nowrap">Create Poll</span>
                  </b-button>
                </div>
              </div>
              <!-- Select list options -->
              <ul class="d-flex align-items-center justify-content-center bottom-options mb-0" @click="page2 = false;">
                <li v-b-modal.create-custompop @click="postType = 'text-post'">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M2.5 5.00001C2.5 4.55798 2.67559 4.13406 2.98816 3.8215C3.30072 3.50894 3.72464 3.33334 4.16667 3.33334H15.8333C16.2754 3.33334 16.6993 3.50894 17.0118 3.8215C17.3244 4.13406 17.5 4.55798 17.5 5.00001V15C17.5 15.442 17.3244 15.866 17.0118 16.1785C16.6993 16.4911 16.2754 16.6667 15.8333 16.6667H4.16667C3.72464 16.6667 3.30072 16.4911 2.98816 16.1785C2.67559 15.866 2.5 15.442 2.5 15V5.00001Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.83203 6.66669H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M5.83203 10H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M5.83203 13.3333H14.1654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Text Post</span>
                </li>
                <li v-b-modal.create-custompop @click="postType = 'photo-post'">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path d="M12.5 6.66669H12.5083" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M2.5 13.3333L6.66667 9.16665C7.44 8.42248 8.39333 8.42248 9.16667 9.16665L13.3333 13.3333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M11.668 11.6667L12.5013 10.8333C13.2746 10.0892 14.228 10.0892 15.0013 10.8333L17.5013 13.3333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Photo</span>
                </li>
                <li v-b-modal.create-custompop @click="postType = 'video-post'">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.33203 5.00001C3.33203 4.55798 3.50763 4.13406 3.82019 3.8215C4.13275 3.50894 4.55667 3.33334 4.9987 3.33334H14.9987C15.4407 3.33334 15.8646 3.50894 16.1772 3.8215C16.4898 4.13406 16.6654 4.55798 16.6654 5.00001V15C16.6654 15.442 16.4898 15.866 16.1772 16.1785C15.8646 16.4911 15.4407 16.6667 14.9987 16.6667H4.9987C4.55667 16.6667 4.13275 16.4911 3.82019 16.1785C3.50763 15.866 3.33203 15.442 3.33203 15V5.00001Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.66797 3.33334V16.6667" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 3.33334V16.6667" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 6.66669H6.66536" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 13.3333H6.66536" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 10H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 6.66669H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M13.332 13.3333H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Video</span>
                </li>
                <li v-b-modal.create-custompop @click="postType = 'quote-post'">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M11.6654 9.16667H14.9987C15.2197 9.16667 15.4317 9.07887 15.588 8.92259C15.7442 8.76631 15.832 8.55435 15.832 8.33333V5.83333C15.832 5.61232 15.7442 5.40036 15.588 5.24408C15.4317 5.0878 15.2197 5 14.9987 5H12.4987C12.2777 5 12.0657 5.0878 11.9094 5.24408C11.7532 5.40036 11.6654 5.61232 11.6654 5.83333V10.8333C11.6654 13.0558 12.7762 14.4442 14.9987 15"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="4" y="5" width="4" height="4" fill="#6E6B7B" />
                        <rect x="12" y="5" width="4" height="4" fill="#6E6B7B" />
                        <path
                          d="M4.16536 9.16667H7.4987C7.71971 9.16667 7.93167 9.07887 8.08795 8.92259C8.24423 8.76631 8.33203 8.55435 8.33203 8.33333V5.83333C8.33203 5.61232 8.24423 5.40036 8.08795 5.24408C7.93167 5.0878 7.71971 5 7.4987 5H4.9987C4.77768 5 4.56572 5.0878 4.40944 5.24408C4.25316 5.40036 4.16536 5.61232 4.16536 5.83333V10.8333C4.16536 13.0558 5.2762 14.4442 7.4987 15"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Quote</span>
                </li>
                <li v-b-toggle.add-event-sidebar>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.33203 5.83332C3.33203 5.3913 3.50763 4.96737 3.82019 4.65481C4.13275 4.34225 4.55667 4.16666 4.9987 4.16666H14.9987C15.4407 4.16666 15.8646 4.34225 16.1772 4.65481C16.4898 4.96737 16.6654 5.3913 16.6654 5.83332V15.8333C16.6654 16.2754 16.4898 16.6993 16.1772 17.0118C15.8646 17.3244 15.4407 17.5 14.9987 17.5H4.9987C4.55667 17.5 4.13275 17.3244 3.82019 17.0118C3.50763 16.6993 3.33203 16.2754 3.33203 15.8333V5.83332Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.332 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M6.66797 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M10 2.5V5.83333" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M3.33203 9.16669H16.6654" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M6.66797 12.5H8.33464V14.1667H6.66797V12.5Z" stroke="#6E6B7B" stroke-width="1.25"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Event</span>
                </li>
                <li v-b-toggle.add-poll-sidebar>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g>
                        <path
                          d="M3.00228 5.99751L8.95061 3.40668C9.04877 3.36461 9.15435 3.34261 9.26114 3.34199C9.36793 3.34137 9.47376 3.36214 9.57239 3.40307C9.67103 3.444 9.76047 3.50426 9.83545 3.58031C9.91042 3.65635 9.96942 3.74664 10.0089 3.84584L14.1123 13.7642C14.1976 13.9653 14.2005 14.1919 14.1204 14.3951C14.0402 14.5984 13.8835 14.762 13.6839 14.8508L7.73645 17.4417C7.63825 17.4839 7.53259 17.506 7.42571 17.5067C7.31883 17.5073 7.21289 17.4866 7.11416 17.4457C7.01543 17.4047 6.9259 17.3444 6.85086 17.2683C6.77583 17.1922 6.7168 17.1018 6.67728 17.0025L2.57395 7.08334C2.48866 6.88222 2.48576 6.65565 2.56587 6.45241C2.64598 6.24917 2.8027 6.08634 3.00228 5.99751Z"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M12.5 3.33334H13.3333C13.5543 3.33334 13.7663 3.42114 13.9226 3.57742C14.0789 3.7337 14.1667 3.94566 14.1667 4.16668V7.08334"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M16.6654 5C16.8854 5.09333 17.0987 5.18083 17.3054 5.2625C17.5088 5.34882 17.6696 5.51241 17.7524 5.71729C17.8353 5.92217 17.8333 6.15155 17.747 6.355L15.832 10.8333"
                          stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>
                    Poll
                  </span>
                </li>
              </ul>
              <!-- Custom Create Popup -->
              <b-modal id="create-custompop" size="lg" title="Large Modal" hide-footer>
                <template #modal-header="{ close }">
                  <!-- Option Select -->
                  <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="postType"
                    :options="postTypeOptions" class="w-auto create-options" :reduce="(val) => val.value"
                    placeholder="Select type.." :clearable="false" />
                  <div class="next-expand d-flex align-items-center">
                    <b-button id="next-btn" pill variant="outline-primary" class="px-3 py-75" v-if="!page2"
                      @click="page2 = true;">
                      Next
                    </b-button>
                    <b-button id="back-edit" pill variant="outline-primary" class="px-3 py-75" v-if="page2"
                      @click="page2 = false;">
                      Back To Editing
                    </b-button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 34 34" fill="none">
                      <g>
                        <path
                          d="M5.66797 8.5C5.66797 7.74855 5.96648 7.02788 6.49783 6.49653C7.02919 5.96518 7.74986 5.66666 8.5013 5.66666H25.5013C26.2527 5.66666 26.9734 5.96518 27.5048 6.49653C28.0361 7.02788 28.3346 7.74855 28.3346 8.5V25.5C28.3346 26.2514 28.0361 26.9721 27.5048 27.5035C26.9734 28.0348 26.2527 28.3333 25.5013 28.3333H8.5013C7.74986 28.3333 7.02919 28.0348 6.49783 27.5035C5.96648 26.9721 5.66797 26.2514 5.66797 25.5V8.5Z"
                          stroke="#A1A1A1" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M21.25 5.66666V28.3333" stroke="#A1A1A1" stroke-width="1.75" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M14.1654 14.1667L11.332 17L14.1654 19.8333" stroke="#A1A1A1" stroke-width="1.75"
                          stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="34" height="34" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="close()" />
                </template>
                <!-- Text Post -->
                <b-form autocomplete="off" id="post-form" v-if="!page2 && postType == 'text-post'">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-input id="title" name="title" placeholder="Start Typing To Add Title..." />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group class="post-content">
                        <QuillInput :toolbar="true" className="post-editor" placeholder="Start Typing To Add Body..."
                          :mentionList="employeeListOptions" />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <file-pond
                  ref="pond"
                  name="files[]"
                  max-file-size="50MB"
                  allow-paste="false"
                  max-files="10"
                  allow-multiple="true"
                  v-bind:files="myFiles"
                  image-preview-height="120px"
                  accepted-file-types="image/*,video/*,application/pdf"
                  file-validate-type-label-expected-types="Expects a PDF, Images, Video file"
                />
                <small class="text-warning flex items-center">
                  File size should be less than 50MB and allowed formats are
                </small>
                <p>( .mp4,.pdf, image)</p>
                    </b-col>
                  </b-row>
                  <div class="attched-file" v-if="page2">
                    <ul class="p-0">
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Attached File Name</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10" @click="close()" />
                          </span>
                        </p>
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </b-form>
                <!-- End Text Post -->
                <!-- Video Post -->
                <b-form autocomplete="off" id="post-form" v-if="!page2 && postType == 'video-post'">
                  <b-row>
                    <b-col md="12">
                      <file-pond ref="pond" name="files[]" max-file-size="50MB" allow-paste="false" max-files="10"
                        allow-multiple="true" v-bind:files="myFiles" image-preview-height="120px"
                        accepted-file-types="video/*" label-idle="Or drop a file, Or paste video URL."
                        file-validate-type-label-expected-types="Expects a PDF, Images, Video file" />
                    </b-col>
                    <b-col md="12">
                      <b-form-group class="post-content">
                        <b-form-textarea placeholder="Start Typing To Add A Description ..." rows="3">
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
                <!-- End Video Post -->
                <!-- Photo Post -->
                <b-form autocomplete="off" id="post-form" v-if="!page2 && postType == 'photo-post'">
                  <b-row>
                    <b-col md="12">
                      <file-pond ref="pond" name="files[]" max-file-size="50MB" allow-paste="false" max-files="10"
                        allow-multiple="true" v-bind:files="myFiles" image-preview-height="120px"
                        accepted-file-types="video/*" label-idle="Or drop a file, Or paste photo URL."
                        file-validate-type-label-expected-types="Expects a PDF, Images, Video file" />
                    </b-col>
                    <b-col md="12">
                      <b-form-group class="post-content">
                        <b-form-textarea placeholder="Start Typing To Add A Description ..." rows="3">
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
                <!-- End Photp Post -->
                <!-- Quote Post -->
                <b-form autocomplete="off" id="post-form" v-if="!page2 && postType == 'quote-post'">
                  <b-row>
                    <b-col md="12">
                      <b-form-group class="post-content">
                        <b-form-textarea placeholder="Start Typing To Add Quote..." rows="20">
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-input id="title" name="title" placeholder="Start Typing To Add Author..." />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <v-select v-model="typeFilter" :options="QuoteSourceTypes" class="w-auto create-options"
                        :clearable="false" />
                    </b-col>
                    <b-col md="7">
                      <b-form-input id="author" name="author" placeholder="Start Typing To Add Author..." />
                    </b-col>
                    <b-col md="2">
                      <b-form-input id="time" name="time" placeholder="Time" />
                    </b-col>
                  </b-row>
                </b-form>
                <!-- End Quote Post -->

                <!-- Back To Editing  -->
                <div class="back-editform community-preview" v-if="page2">
                  <b-card no-body class="my-1">
                    <b-card-body>
                      <div class="d-flex justify-content-between align-items-start mb-2 post-header">
                        <div class="d-flex align-items-center">
                          <!-- avatar -->
                          <b-avatar :src="require('@/assets/images/avatars/6.png')" style="background: #" size="40"
                            class="mr-75" />
                          <!--/ avatar -->
                          <div class="profile-user-info">
                            <h6 class="mb-0 font-weight-bolder">
                              Timothy Diep
                            </h6>
                            <small style="font-size: 12px">
                              Customer Success Manager
                            </small>
                            <p class="d-block d-md-none">
                              Oct 13, 2023
                            </p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="d-none d-md-block mb-0">
                            Oct 13, 2023
                          </p>
                          <b-dropdown class="blog-dropdown" variant="link" size="sm"
                            toggle-class="text-decoration-none p-0" no-caret>
                            <template v-slot:button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16"
                                class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                                title="More Options" />
                            </template>
                            <b-dropdown-item class="btn-sm p-0 text-muted" v-b-toggle.edit-sidebar>
                              <!-- @click="editRecord(data)" -->
                              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                              <span class="">Edit</span>
                            </b-dropdown-item>
                            <b-dropdown-item class="btn-sm p-0 text-muted">
                              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                              <span class="">Approve Post</span>
                            </b-dropdown-item>
                            <b-dropdown-item class="btn-sm p-0 text-muted">
                              <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                              <span class="">Reject Post</span>
                            </b-dropdown-item>
                            <b-dropdown-item class="btn-sm p-0 text-muted">
                              <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                              <span class="">Copy</span>
                            </b-dropdown-item>

                            <b-dropdown-item class="btn-sm p-0">
                              <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                              <span class="">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                  <div class="attched-file">
                    <ul class="p-0">
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>First File Name</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10" @click="close()" />
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Second File Name</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10" @click="close()" />
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g>
                              <path
                                d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath>
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Third File Name</span>
                          <span class="close d-flex align-items-center justify-content-center">
                            <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10" @click="close()" />
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <b-row>
                    <b-col cols="6" class="select-channel">
                      <v-select v-model="typeChannel" :options="communityChannels" class="w-auto create-options"
                        placeholder="Select A Channel" :clearable="false" />
                    </b-col>
                    <b-col cols="6" class="select-post d-flex align-items-center justify-content-end">
                      <div class="demo-inline-spacing justify-content-start">
                        <b-nav-item-dropdown right class="dropdown-user">
                          <template #button-content>
                            <div class="d-sm-flex d-none user-nav text-center">
                              <p class="user-name font-weight-bolder mb-0 w-100"> Post </p>
                            </div>
                          </template>
                          <b-dropdown-item>
                            <span>Schedule Post</span>
                          </b-dropdown-item>
                        </b-nav-item-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- End Back To Editing -->
              </b-modal>
            </div>
          </div>
        </b-overlay>
        <div class="">
          <div v-show="filterByType != 'event' && filterByType != 'poll'" ref="div-scroll" v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="isBusy" infinite-scroll-distance="10">
            <!-- Start Pinned Post -->
            <b-card no-body class="post-card pinned-post" v-if="pinnedPosts.length && pinnedPosts[0].length > 1">
              <b-card-body>
                <b-carousel id="post_pin" :interval="4000" :controls="pinnedPosts.length > 1">
                  <b-carousel-slide v-for="(chunk, ind) in pinnedPosts">
                    <template #img>
                      <b-card no-body v-for="(data, index) in chunk" :key="'post-' + index"
                        :id="'post-card-' + data.hashid" class="post-card">
                        <!-- @click="viewPost(data)" -->
                        <b-card-body @click="viewPost(data)" :id="data.hashid" v-observe-visibility="{
                          callback: (isVisible, entry) =>
                            visibilityChanged(isVisible, entry, data),
                          once: true,
                          throttle: 0,
                          throttleOptions: {
                            leading: 'visible',
                          },
                          intersection: {
                            threshold: 0,
                          },
                        }">
                          <div class="d-flex justify-content-between align-items-center mb-50">
                            <div class="d-flex align-items-center">
                              <!-- avatar -->
                              <b-avatar :src="data.user.avatar" style="background: transparent" size="38"
                                class="mr-1 profile-img" />
                              <!--/ avatar -->
                              <div class="profile-user-info">
                                <h6 class="mb-0 font-weight-bolder">
                                  {{ data.user.name }}
                                </h6>
                                <small style="font-size: 12px">
                                  {{ data.user.designation }}
                                </small>
                                <p class="d-block d-md-none">
                                  {{ data.created_at | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="d-flex align-items-center">
                              <b-badge v-if="data.is_scheduled == 'true' &&
                                data.is_published == 'false'
                                " pill variant="light-info">Scheduled</b-badge>
                              <b-badge v-if="data.is_approved == 'false' &&
                                data.is_published == 'true'
                                " class="mr-50" pill variant="light-danger">Need Approval</b-badge>
                              <b-badge v-if="data.is_draft == 'true'" pill class="ml-50"
                                variant="light-warning">Draft</b-badge>
                              <b-img v-if="data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin-24px.png')
                                " width="18" class="mr-50 pin-icon" style="background-color: transparent"
                                v-b-tooltip.hover.v-default title="Post is pinned by admin" />
                              <p class="d-none d-md-block">
                                {{ data.created_at | formatDate }}
                              </p>
                              <b-dropdown class="blog-dropdown" variant="link" size="sm"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <feather-icon icon="MoreVerticalIcon" size="14"
                                    class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                                    title="More Options" />
                                </template>

                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_pinned == 'false'
                                  " @click="pinPost(data)">
                                  <b-img :src="require('@/assets/images/icons/pin-16px.png')
                                    " size="16" class="mr-50" style="background-color: transparent" />
                                  <span class="">Pin Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_pinned == 'true'
                                  " @click="unpinPost(data)">
                                  <b-img :src="require('@/assets/images/icons/pin-16px.png')
                                    " size="16" class="mr-50" style="background-color: transparent" />
                                  <span class="">Un-Pin Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_approved == 'false'
                                  " @click="approvePost(data)">
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Approve Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                                  data.is_approved == 'true'
                                  " @click="rejectPost(data)">
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Reject Post</span>
                                </b-dropdown-item>
                                <b-dropdown-item class="btn-sm p-0 text-muted" @click="viewPost(data)"
                                  v-b-toggle.edit-sidebar v-if="($can('post_edit') &&
                                      data.user.hashid == userData.hashid) ||
                                    $can('Zircly Admin')
                                    ">
                                  <!-- @click="editRecord(data)" -->
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Edit</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-clipboard:copy="shareUrl(data.hashid)" v-clipboard:success="onCopy"
                                  v-clipboard:error="onError" class="btn-sm p-0 text-muted">
                                  <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Copy</span>
                                </b-dropdown-item>

                                <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                                    data.user.hashid == userData.hashid) ||
                                  $can('Zircly Admin')
                                  ">
                                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                                  <span class="">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>

                          <div>
                            <div class="" style="position: relative">
                              <div class="" :class="wordCount(data.body) > 100
                                  ? 'post-card-summary'
                                  : ''
                                " v-b-toggle.add-sidebar-view @click="viewPost(data)" style="
                                max-height: 20em;
                                overflow: hidden;
                                text-overflow: ellipsis;
                              ">
                                <b-card-text>
                                  <h2 class="card-header mt-2 mt-lg-0">
                                    {{ data.title }}
                                  </h2>
                                </b-card-text>
                                <b-card-text v-html="data.body"> </b-card-text>
                              </div>
                            </div>
                            <p v-if="wordCount(data.body) > 100" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                              class="font-weight-bolder">
                              <u>Read More</u>
                            </p>

                            <!-- post img -->
                            <!-- <div class="mt-1 mb-1 d-flex justify-content-center">
                          <b-img
                            v-if="data.image"
                            v-b-toggle.add-sidebar-view
                            @click="viewPost(data)"
                            fluid
                            rounded
                            :src="data.image"
                class="post-img"
                          />
                        </div> -->
                            <!--/ post img -->

                            <!-- <div
                          class="d-flex justify-content-center justify-content-lg-start"
                          v-if="data.collage_img.length == 1"
                        >
                          <b-img
                            v-if="
                              data.collage_img[0].type == 'image/png' ||
                              data.collage_img[0].type == 'image/jpeg'
                            "
                            v-b-toggle.add-sidebar-view
                            @click="viewPost(data)"
                            fluid
                            rounded
                            class="mb-25"
                            :src="data.collage_img[0].link"
                            style="max-height: 400px; max-width: 100%"
                          />
                        </div>
                        <div
                          v-if="data.collage_img.length == 2"
                          class="mb-1 row"
                          v-b-toggle.add-sidebar-view
                          @click="viewPost(data)"
                        >
                          <div
                            v-if="
                              data.collage_img[0].type == 'image/png' ||
                              data.collage_img[0].type == 'image/jpeg'
                            "
                            :style="
                              'background-image:url(' +
                              data.collage_img[0].link +
                              ');min-height:400px;border-radius:3px;background-size: cover;'
                            "
                            class="col-6"
                          ></div>
                          <div
                            :style="'min-height:400px;'"
                            class="col-6 mb-0 b-r-0 post-collage"
                          >
                            <img
                              v-if="
                                data.collage_img[1].type == 'image/png' ||
                                data.collage_img[1].type == 'image/jpeg'
                              "
                              :src="data.collage_img[1].link"
                              class="card-img h-100 w-100"
                              alt="..."
                            />
                            <div class="card-img-overlay w-100">
                              <h5 class="card-title text-white"><u>More</u></h5>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="data.collage_img.length >= 3"
                          class="mb-1 row post-collage"
                          v-b-toggle.add-sidebar-view
                          @click="viewPost(data)"
                        >
                          <div
                            v-if="
                              data.collage_img[0].type == 'image/png' ||
                              data.collage_img[0].type == 'image/jpeg'
                            "
                            :style="
                              'background-image:url(' +
                              data.collage_img[0].link +
                              ');min-height:400px; border-radius:5px;background-size: cover;'
                            "
                            class="col-6"
                          ></div>
                          <div class="col-6">
                            <div
                              v-if="
                                data.collage_img[1].type == 'image/png' ||
                                data.collage_img[1].type == 'image/jpeg'
                              "
                              :style="
                                'background-image:url(' +
                                data.collage_img[1].link +
                                ');height:50%;border-radius:5px;background-size: cover;'
                              "
                              class="row"
                            ></div>
                            <div class="card bg-dark mb-0 row b-r-0 text-white">
                              <img
                                v-if="
                                  data.collage_img[2].type == 'image/png' ||
                                  data.collage_img[2].type == 'image/jpeg'
                                "
                                :src="data.collage_img[2].link"
                                class="card-img"
                                alt="..."
                              />
                              <div class="card-img-overlay">
                                <h5 class="card-title text-white">
                                  <u>+ {{ data.collage_img.length - 2 }} More</u>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div> -->
                          </div>
                          <!-- likes comments  share-->
                          <b-row class="mt-50 like-button top-cmd" @click.stop>
                            <b-col cols="8" class="d-flex justify-content-start mb-0">
                              <b-link class="d-flex align-items-center text-muted text-nowrap">
                                <div class="likes" v-if="!data.youLiked">
                                  <!-- <feather-icon
                                  icon="HeartIcon"
                                  class="mr-50 text-primary profile-icon"
                                  size="24"
                                  title="Like"
                                  @click="likePost(data, index, 'like')"
                                /> -->
                                  <b-img :src="require('@/assets/images/icons/like/beforeLike.png')
                                    " width="22" title="Like" class="mr-50 active-like"
                                    @click="likePost(data, index, 'like')"></b-img>
                                  <div class="emoji-reactions">
                                    <div class="emoji-item" @click="likePost(data, index, 'like')">
                                      <b-img :src="require('@/assets/images/icons/like/like.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'clap')">
                                      <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'love')">
                                      <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'support')">
                                      <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'idea')">
                                      <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'smile')">
                                      <b-img :src="require('@/assets/images/icons/like/smile.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                                    </div>
                                  </div>
                                </div>
                                <div class="likes" v-if="data.youLiked && data.likeType">
                                  <div v-if="data.likeType == 'like'" @click="likePost(data, index, 'like')">
                                    <b-img :src="require('@/assets/images/icons/like/like.png')
                                      " width="24" title="Like" class="mr-50 active-like"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'clap'" @click="likePost(data, index, 'clap')">
                                    <b-img :src="require('@/assets/images/icons/like/clapping.png')
                                      " width="24" title="Celebrate" class="mr-50"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'love'" @click="likePost(data, index, 'love')">
                                    <b-img :src="require('@/assets/images/icons/like/love-icon.png')
                                      " width="24" title="Love" class="mr-50"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'support'" @click="likePost(data, index, 'support')">
                                    <b-img :src="require('@/assets/images/icons/like/hand-heart.png')
                                      " width="24" title="Support" class="mr-50"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'idea'" @click="likePost(data, index, 'idea')">
                                    <b-img :src="require('@/assets/images/icons/like/lightbulb.png')
                                      " width="24" title="Insightful" class="mr-50 active-idea"></b-img>
                                  </div>
                                  <div v-if="data.likeType == 'smile'" @click="likePost(data, index, 'smile')">
                                    <b-img :src="require('@/assets/images/icons/like/smile.png')
                                      " width="24" title="Funny" class="mr-50"></b-img>
                                  </div>

                                  <div class="emoji-reactions">
                                    <div class="emoji-item" @click="likePost(data, index, 'like')">
                                      <b-img :src="require('@/assets/images/icons/like/like.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'clap')">
                                      <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'love')">
                                      <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'support')">
                                      <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'idea')">
                                      <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                                    </div>
                                    <div class="emoji-item" @click="likePost(data, index, 'smile')">
                                      <b-img :src="require('@/assets/images/icons/like/smile.svg')
                                        " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                                    </div>
                                  </div>
                                </div>
                                <span v-if="data.likes_count" class="text-primary" style="margin-left: 4px"
                                  v-b-modal="'show-likes-' + data.hashid">{{ kFormatter(data.likes_count) }}</span>
                              </b-link>
                              <!-- <div class="d-none d-lg-flex mobile-avatar align-items-center">
                            <b-avatar-group size="22" class="ml-50">
                              <div class="d-flex reaction-details"  v-for="(like, i) in getMosReactions(postLikes)">
                                <b-img v-if="like == 'love'" :src="require('@/assets/images/icons/like/love-emoji.png')" width="22" height="22"/>
                                <b-img v-if="like == 'idea'" :src="require('@/assets/images/icons/like/idea-emoji.png')" width="22" height="22"/>
                                <b-img v-if="like == 'clap'" :src="require('@/assets/images/icons/like/clap-emoji.png')" width="22" height="22"/>
                                <b-img v-if="like == 'like'" :src="require('@/assets/images/icons/like/thumb-emoji.png')" width="22" height="22"/>
                                <b-img v-if="like == 'support'" :src="require('@/assets/images/icons/like/handlove-emji.png')" width="22" height="22"/>
                                <b-img v-if="like == 'smile'" :src="require('@/assets/images/icons/like/smile-emji.png')" width="22" height="22"/>
                              </div>
                              <span class="pl-25"></span>
                              <b-avatar
                                v-for="(like, i) in postLikes"
                                v-if="i < 3"
                                :key="'avatar-' + i"
                                v-b-tooltip.hover.bottom="like.user.name"
                                class="pull-up m-0"
                                :src="like.user.avatar"
                              />
                            </b-avatar-group>
                            <b-link
                              v-if="data.likes_count > 3"
                              class="text-primary text-nowrap ml-25"
                              v-b-modal="'show-likes-' + data.hashid"
                            >
                              +{{ data.likes_count - 3 }} more
                            </b-link>
                          </div>
            <div class="d-flex d-lg-none mobile-avatar align-items-center">
                            <b-avatar-group size="24" class="ml-50">
                              <b-avatar
                                v-for="(like, i) in postLikes"
                                v-if="i < 3"
                                :key="'avatar-' + i"
                                v-b-tooltip.hover.bottom="like.user.name"
                                class="pull-up m-0"
                                :src="like.user.avatar"
                              />
                            </b-avatar-group>
                            <b-link
                              v-if="data.likes_count > 3"
                              class="text-primary text-nowrap ml-25"
                              v-b-modal="'show-likes-' + data.hashid"
                            >
                              +{{ data.likes_count - 3 }} more
                            </b-link>
                          </div> -->
                            </b-col>
                            <b-modal :id="'show-likes-' + data.hashid" hide-footer modal-class="modal-primary modal-likes"
                              scrollable title="Reactions" class="text-danger" @show="getPostReactions(data.hashid)">
                              <b-list-group v-if="postLikes && postLikes.length">
                                <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                                  class="d-flex align-items-center">
                                  <b-avatar :src="like.user.avatar" size="24" class="mr-1" />
                                  {{ like.user.name }}
                                  <div class="ml-auto" v-if="like.type == 'like'">
                                    <b-img :src="require('@/assets/images/icons/like/like.png')
                                      " width="24" title="Like"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'clap'">
                                    <b-img :src="require('@/assets/images/icons/like/clapping.png')
                                      " width="24" title="Celebrate"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'love'">
                                    <b-img :src="require('@/assets/images/icons/like/love-icon.png')
                                      " width="24" title="Love"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'support'">
                                    <b-img :src="require('@/assets/images/icons/like/hand-heart.png')
                                      " width="24" title="Support"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'idea'">
                                    <b-img :src="require('@/assets/images/icons/like/lightbulb.png')
                                      " width="24" title="Insightful"></b-img>
                                  </div>
                                  <div class="ml-auto" v-if="like.type == 'smile'">
                                    <b-img :src="require('@/assets/images/icons/like/smile.png')
                                      " width="24" title="Funny"></b-img>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                              <b-card-text v-else-if="postLikes && postLikes.length == 0">
                                <div class="item error text-center my-2 empty-data">
                                  <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200"
                                    class="mr-50" style="background-color: transparent" />
                                  <p class="d-block mb-1">No Records Found!</p>
                                </div>
                              </b-card-text>
                              <b-card-text v-else-if="postLikes == null">
                                <div class="d-flex align-items-center">
                                  <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150"
                                    class="mr-50" style="background-color: transparent" />
                                  <h4> Loading </h4>
                                </div>
                              </b-card-text>
                            </b-modal>
                            <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0">
                              <!-- <b-link v-b-toggle.add-sidebar-view @click="viewPost(data)"
                                class="text-body text-nowrap d-flex align-items-center">
                                <feather-icon icon="MessageCircleIcon" size="24" class="profile-icon mr-50 text-primary"
                                  v-b-tooltip.hover.v-default title="Comments" />
                                <span v-if="data.comments_count" class="text-muted mr-50">{{
                                  kFormatter(data.comments_count) }}</span>
                              </b-link>
                              <b-link v-if="data.visit_count_total"
                                class="text-body text-nowrap d-flex align-items-center" @click="showVisits(data.hashid)">
                                <feather-icon icon="EyeIcon" size="24" class="profile-icon mr-50 text-primary"
                                  v-b-tooltip.hover.v-default title="Views" />
                                <span class="text-muted mr-50">{{
                                  kFormatter(data.visit_count_total)
                                }}</span>
                              </b-link> -->
                              <b-link
                  v-if="data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  @click="viewPost(data)"
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(data.comments_count) }}</span>
                </b-link>
                <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                />
                <b-link v-if="data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-2"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                />

                              <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="modal-primary"
                                scrollable title="Views" class="text-danger">
                                <b-list-group v-if="postViews && postViews.length">
                                  <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid"
                                    class="d-flex align-items-center">
                                    <b-avatar :src="user.avatar" size="24" class="mr-1" />
                                    {{ user.name }}
                                  </b-list-group-item>
                                </b-list-group>
                                <b-card-text v-else-if="isBusy">
                                  <div class="d-flex align-items-center">
                                    <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150"
                                      class="mr-50" style="background-color: transparent" />
                                    <h4> Loading </h4>
                                  </div>
                                </b-card-text>
                                <b-card-text v-else>
                                  <div class="item error text-center my-2 empty-data">
                                    <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200"
                                      class="mr-50" style="background-color: transparent" />
                                    <p class="d-block mb-1">No Records Found!</p>
                                  </div>
                                </b-card-text>
                              </b-modal>
                              <b-link class="text-body text-nowrap d-flex align-items-center"
                                @click="savePost(data, index)">
                                <feather-icon icon="BookmarkIcon" class="mr-25 text-primary" :class="data.youSaved ? 'saved-post' : 'profile-icon'
                                  " v-b-tooltip.hover.v-default :title="data.youSaved
      ? 'Remove from Saved'
      : 'Save Post'
    " size="24" />
                              </b-link>
                            </b-col>
                          </b-row>
                          <!--/ likes comments  share-->
                        </b-card-body>
                        <!-- <b-card-footer> -->
                        <!-- comments -->
                        <!-- <div
                        v-for="(comment, ind) in data.latest_comments"
                        :key="'comment-' + ind"
                        class="d-flex align-items-start mb-1"
                      >
                        <b-avatar :src="comment.user.avatar" size="34" class="mr-75" />
                        <div
                          v-if="!editComments.includes(comment.hashid)"
                          class="profile-user-info w-100"
                        >
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <div class="profile-user-info">
                              <h6 class="mb-0">
                                {{ comment.user.name }}
                              </h6>
                              <small style="font-weight: 550; font-size: 12px">
                                {{ comment.user.designation }}
                              </small>
                            </div>
                            <div
                              class="
                                d-flex
                                flex-column flex-lg-row
                                align-items-lg-center
                              "
                            >
                              <span class="small mr-50">
                                {{ comment.created_at }}
                              </span>
                              <div class="d-none d-lg-block">
                                <b-link
                                  @click="like(comment.hashid, index)"
                                  class="text-body btn btn-icon rounded-circle p-50"
                                  v-b-tooltip.html.hover="
                                    'Liked by ' + getNames(comment.likes)
                                  "
                                >
                                  <feather-icon
                                    icon="HeartIcon"
                                    size="18"
                                    class="mr-25"
                                    :class="
                                      comment.youLiked
                                        ? 'profile-likes'
                                        : 'profile-icon'
                                    "
                                  />
                                  <span
                                    v-if="comment.likes_count"
                                    class="text-muted align-middle"
                                    >{{ kFormatter(comment.likes_count) }}</span
                                  >
                                </b-link>
                                <b-link
                                  v-if="comment.user.hashid == userData.hashid"
                                  class="btn btn-icon text-primary rounded-circle p-50"
                                  @click="
                                    editComments.pop(),
                                      editComments.push(comment.hashid)
                                  "
                                >
                                  <feather-icon icon="EditIcon" size="18" 
                                  v-b-tooltip.hover.v-default
                                  title="Comment Edit"/>
                                </b-link>
                                <b-link
                                  v-if="comment.user.hashid == userData.hashid"
                                  class="text-danger btn btn-icon rounded-circle p-50"
                                  @click="
                                    deleteComment(comment.hashid, data.hashid, index)
                                  "
                                >
                                  <feather-icon icon="TrashIcon" size="18" 
                                  v-b-tooltip.hover.v-danger
                                  title="Delete Comment"/>
                                </b-link>
                                <b-link
                                  class="btn btn-icon text-primary rounded-circle p-50"
                                  @click="addReply(comment, index)"
                                >
                                  <feather-icon icon="CornerUpLeftIcon" size="18" 
                                  v-b-tooltip.hover.v-default
                                  title="Reply to Comment" />
                                </b-link>
                              </div>
                            </div>
                          </div>
                          <p
                            v-html="comment.comment"
                            class="mb-0 mt-1"
                            style="letter-spacing: 1px; font-size: 14px"
                          ></p>
                          <div class="d-flex mb-lg-0 d-lg-none">
                            <b-link
                              @click="like(comment.hashid, index)"
                              class="
                                text-body
                                btn btn-icon
                                rounded-circle
                                pl-0
                                pt-50
                                pb-50
                                pr-50
                              "
                              v-b-tooltip.html.hover="
                                'Liked by ' + getNames(comment.likes)
                              "
                            >
                              <feather-icon
                                icon="HeartIcon"
                                size="18"
                                class="mr-25"
                                :class="
                                  comment.youLiked ? 'profile-likes' : 'profile-icon'
                                "
                              />
                              <span
                                v-if="comment.likes_count"
                                class="text-muted align-middle"
                                >{{ kFormatter(comment.likes_count) }}</span
                              >
                            </b-link>
                            <b-link
                              v-if="comment.user.hashid == userData.hashid"
                              class="btn btn-icon text-primary rounded-circle p-50"
                              @click="
                                editComments.pop(), editComments.push(comment.hashid)
                              "
                            >
                              <feather-icon icon="EditIcon" size="18" 
                              v-b-tooltip.hover.v-default
                              title="Edit Comment"/>
                            </b-link>
                            <b-link
                              v-if="comment.user.hashid == userData.hashid"
                              class="text-danger btn btn-icon rounded-circle p-50"
                              @click="deleteComment(comment.hashid, data.hashid, index)"
                            >
                              <feather-icon icon="TrashIcon" size="18" 
                              v-b-tooltip.hover.v-danger
                              title="Delete Comment"/>
                            </b-link>
                            <b-link
                              class="btn btn-icon text-primary rounded-circle p-50"
                              @click="addReply(comment, index)"
                            >
                              <feather-icon icon="CornerUpLeftIcon" size="18" 
                              v-b-tooltip.hover.v-default
                              title="Repy to Comment" />
                            </b-link>
                          </div>
                          <div>
                            <Replies
                              v-if="comment.reply_count"
                              :itemId="comment.hashid"
                              :reply_count="comment.reply_count"
                              :mentionList="employeeListOptions"
                            />
                          </div>
                        </div>
                        <div
                          v-else="editComments.includes(comment.hashid)"
                          class="
                            border
                            p-751
                            d-flex
                            mb-0
                            flex-row
                            align-items-center
                            justify-content-around
                            commentBox
                            round
                            w-100
                          "
                        >
                          <QuillInput
                            placeholder="Add a comment..."
                            className="comment-box"
                            :id="'comment-field-' + comment.hashid"
                            :value="comment.comment"
                            :toolbar="false"
                            :mentionList="employeeListOptions"
                          />
                          <a
                            @click="editComment(comment, data.hashid, index)"
                            class="
                              nav-link1
                              font-weight-bold
                              d-block
                              border-0
                              post-btn
                              text-primary
                              mr-1
                            "
                          >
                            Save
                          </a>
                        </div>
                        <b-link
                          v-if="editComments.includes(comment.hashid)"
                          class="
                            btn btn-icon
                            rounded-circle
                            btn-outline-danger
                            ml-75
                            p-50
                          "
                          @click="editComments.pop()"
                        >
                          <feather-icon icon="XIcon" size="18"  
                          v-b-tooltip.hover.v-default
                          title="Cancel"/>
                        </b-link>
                      </div>
                      <div
                        v-if="comment_id"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          Reply to
                          <b-link class="nav-links ml-1">
                            {{ reply_to }}
                          </b-link>
                        </div>
                        <b-link
                          class="text-primary btn btn-icon rounded-circle p-50"
                          @click="removeReply"
                        >
                          <feather-icon icon="XIcon" size="18" 
                          v-b-tooltip.hover.v-default
                          title="Cancel"/>
                        </b-link>
                      </div>
                      <div
                        class="
                          border
                          p-751
                          d-flex
                          mb-0
                          flex-row
                          align-items-center
                          justify-content-around
                          commentBox
                          round
                        "
                      >
                        <emoji-picker v-if="false" @emoji="append" :search="search">
                          <feather-icon icon="SmileIcon" size="24" class="text-primary"
                            style="margin-left:5px;"
                            slot="emoji-invoker"
                            slot-scope="{ events: { click: clickEvent } }"
                            @click.stop="clickEvent"/>
                          <div
                            slot="emoji-picker"
                            slot-scope="{ emojis, insert, display }"
                          >
                            <div class="emoji-picker"
                              >
                              <div class="emoji-picker__search">
                                <input
                                  type="text"
                                  class="w-100"
                                  v-model="search"
                                  v-focus
                                />
                              </div>
                              <div>
                                <div
                                  v-for="(emojiGroup, category) in emojis"
                                  :key="'category-' + category"
                                >
                                  <h5>
                                    <b>{{ category }}</b>
                                  </h5>
                                  <div class="emojis">
                                    <span
                                      v-for="(emoji, emojiName) in emojiGroup"
                                      :key="'emojiName-' + emojiName"
                                      @click="
                                        setInputId('comment-field-' + index);
                                        insert(emoji);
                                      "
                                      :title="emojiName"
                                      >{{ emoji }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </emoji-picker>
                        <QuillInput
                          placeholder="Add a comment..."
                          className="comment-box"
                          :id="'comment-field-' + index"
                          :toolbar="false"
                          :mentionList="employeeListOptions"
                        />
                        
                        <a
                          @click="addComment(data, index)"
                          class="
                            font-weight-bold
                            post-btn
                            text-primary
                            mr-1
                          "
                        >
                          <strong><b>Post</b></strong>
                        </a>
                      </div>

                      <input type="text" v-show="false" v-model="userSearch" />

                      <div id="editor-container"></div>
                      <div
                        class="list-group"
                        style="max-height: 200px; overflow-y: scroll"
                      >
                        <div
                          class="item fruit list-group-item list-group-item-action br-0"
                          v-for="user in filteredList()"
                          :key="user.code"
                        >
                          <p
                            class="mb-0"
                            @click="addTag(user.label, 'comment-field-' + index)"
                          >
                            {{ user.label }}
                          </p>
                        </div>
                        <div
                          class="item error"
                          v-if="userSearch && !filteredList().length"
                        >
                          <p>No results found!</p>
                        </div>
                      </div>
                    </b-card-footer> -->
                      </b-card>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </b-card-body>
            </b-card>
            <!-- End Pinned Post -->
            <b-card no-body v-for="(data, index) in posts" v-if="(pinnedPosts.length && pinnedPosts[0].length < 2) ||
              data.is_pinned == 'false'
              " :key="'post-' + index" :id="'post-card-' + data.hashid" class="post-card">
              <!-- @click="viewPost(data)" -->
              <b-card-body :id="data.hashid" @click="viewPost(data)" v-observe-visibility="{
                callback: (isVisible, entry) =>
                  visibilityChanged(isVisible, entry, data),
                once: true,
                throttle: 0,
                throttleOptions: {
                  leading: 'visible',
                },
                intersection: {
                  threshold: 0,
                },
              }">
                <div class="d-flex justify-content-between align-items-center mb-2 post-header">
                  <div class="d-flex align-items-center">
                    <!-- avatar -->
                    <b-avatar :src="data.user.avatar" style="background: transparent" size="40" class="mr-75" />
                    <!--/ avatar -->
                    <div class="profile-user-info">
                      <h6 class="mb-0 font-weight-bolder">
                        {{ data.user.name }}
                      </h6>
                      <small style="font-size: 12px">
                        {{ data.user.designation }}
                      </small>
                      <p class="d-block d-md-none">
                        {{ data.created_at | formatDate }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-badge v-if="data.is_scheduled == 'true' &&
                      data.is_published == 'false'
                      " pill variant="light-info">Scheduled</b-badge>
                    <b-badge class="mr-50" v-if="data.is_approved == 'false' &&
                      data.is_published == 'true'
                      " pill variant="light-danger">Need Approval</b-badge>

                    <b-badge v-if="data.is_draft == 'true'" pill class="ml-50" variant="light-warning">Draft</b-badge>
                    <b-img v-if="data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin-24px.png')" size="24"
                      class="mr-1 pin-icon" style="background-color: transparent" v-b-tooltip.hover.v-default
                      title="Post is pinned by admin" />
                    <p class="d-none d-md-block">
                      {{ data.created_at | formatDate }}
                    </p>
                    <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none"
                      no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted"
                          v-b-tooltip.hover.v-default title="More Options" />
                      </template>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'false'" @click="pinPost(data)">
                        <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Pin Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && data.is_pinned == 'true'" @click="unpinPost(data)">
                        <b-img :src="require('@/assets/images/icons/pin-16px.png')" size="16" class="mr-50"
                          style="background-color: transparent" />
                        <span class="">Un-Pin Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-b-toggle.edit-sidebar @click="viewPost(data)" v-if="($can('post_edit') &&
                          data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <!-- @click="editRecord(data)" -->
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'false'
                        " @click="approvePost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Approve Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
                        data.is_approved == 'true'
                        " @click="rejectPost(data)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Reject Post</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-clipboard:copy="shareUrl(data.hashid)" v-clipboard:success="onCopy"
                        v-clipboard:error="onError" class="btn-sm p-0 text-muted">
                        <feather-icon icon="CopyIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Copy</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)" v-if="($can('post_delete') &&
                          data.user.hashid == userData.hashid) ||
                        $can('Zircly Admin')
                        ">
                        <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary" />
                        <span class="">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

                <div class="post-body">
                  <div v-b-toggle.add-sidebar-view @click="viewPost(data)" class="" style="position: relative">
                    <div class="" :class="wordCount(data.body) > 100 ? 'post-card-summary' : ''
                      " style="
                      max-height: 20em;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
                      <b-card-text>
                        <h2 class="card-header pb-1 mt-lg-0">{{ data.title }}</h2>
                      </b-card-text>
                      <b-card-text v-html="data.body"> </b-card-text>
                    </div>
                  </div>
                  <p v-if="wordCount(data.body) > 100" v-b-toggle.add-sidebar-view @click="viewPost(data)"
                    class="font-weight-bolder text-right see-more">
                    <u>...See more</u>
                  </p>

                  <!-- post img -->
                  <div class="mt-1 mb-1 d-flex justify-content-center">
                    <b-img v-if="data.image" v-b-toggle.add-sidebar-view @click="viewPost(data)" fluid rounded
                      :src="data.image" class="post-img" />
                  </div>
                  <!--/ post img -->

                  <div class="d-flex justify-content-center justify-content-lg-start" v-if="data.collage_img.length == 1">
                    <b-img v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " v-b-toggle.add-sidebar-view @click="viewPost(data)" fluid rounded class="mb-25"
                      :src="data.collage_img[0].link" style="max-height: 400px; max-width: 100%" />
                  </div>
                  <div v-if="data.collage_img.length == 2" class="mb-1 row" v-b-toggle.add-sidebar-view
                    @click="viewPost(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');min-height:400px;border-radius:3px;background-size: cover;'
    " class="col-6"></div>
                    <div :style="'min-height:400px;'" class="col-6 mb-0 b-r-0 post-collage">
                      <img v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :src="data.collage_img[1].link" class="card-img h-100 w-100" alt="..." />
                      <div class="card-img-overlay w-100">
                        <h5 class="card-title text-white"><u>More</u></h5>
                      </div>
                    </div>
                  </div>
                  <div v-if="data.collage_img.length >= 3" class="mb-1 row post-collage" v-b-toggle.add-sidebar-view
                    @click="viewPost(data)">
                    <div v-if="data.collage_img[0].type == 'image/png' ||
                      data.collage_img[0].type == 'image/jpeg'
                      " :style="'background-image:url(' +
    data.collage_img[0].link +
    ');min-height:400px; border-radius:5px;background-size: cover;'
    " class="col-6"></div>
                    <div class="col-6">
                      <div v-if="data.collage_img[1].type == 'image/png' ||
                        data.collage_img[1].type == 'image/jpeg'
                        " :style="'background-image:url(' +
    data.collage_img[1].link +
    ');height:50%;border-radius:5px;background-size: cover;'
    " class="row"></div>
                      <div class="card bg-dark mb-0 row b-r-0 text-white">
                        <img v-if="data.collage_img[2].type == 'image/png' ||
                          data.collage_img[2].type == 'image/jpeg'
                          " :src="data.collage_img[2].link" class="card-img" alt="..." />
                        <div class="card-img-overlay">
                          <h5 class="card-title text-white">
                            <u>+ {{ data.collage_img.length - 2 }} More</u>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- likes comments  share-->
                <b-row class="mt-50 like-button top-cmd" @click.stop>
                  <b-col cols="8" class="d-flex justify-content-start mb-0">
                    <b-link class="d-flex align-items-center text-muted text-nowrap">
                      <div class="likes" v-if="!data.youLiked">
                        <!-- <feather-icon
                        icon="HeartIcon"
                        class="mr-50 text-primary profile-icon"
                        size="24"
                        title="Like"
                        @click="likePost(data, index, 'like')"
                      /> -->
                        <!-- <b-img
                        :src="
                          require('@/assets/images/icons/like/beforeLike.png')
                        "
                        width="22"
                        title="Like"
                        class="mr-50 active-like"
                        @click="likePost(data, index, 'like')"
                      ></b-img> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none"
                          title="Like" class="mr-50 active-like" @click="likePost(data, index, 'like')">
                          <g>
                            <path
                              d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667"
                              stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'support')">
                            <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'smile')">
                            <b-img :src="require('@/assets/images/icons/like/smile.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                          </div>
                        </div>
                      </div>
                      <div class="likes" v-if="data.youLiked && data.likeType">
                        <div v-if="data.likeType == 'like'" @click="likePost(data, index, 'like')">
                          <b-img :src="require('@/assets/images/icons/like/like.png')" width="24" title="Like"
                            class="mr-50 active-like"></b-img>
                        </div>
                        <div v-if="data.likeType == 'clap'" @click="likePost(data, index, 'clap')">
                          <b-img :src="require('@/assets/images/icons/like/clapping.png')
                            " width="24" title="Celebrate" class="mr-50"></b-img>
                        </div>
                        <div v-if="data.likeType == 'love'" @click="likePost(data, index, 'love')">
                          <b-img :src="require('@/assets/images/icons/like/love-icon.png')
                            " width="24" title="Love" class="mr-50"></b-img>
                        </div>
                        <div v-if="data.likeType == 'support'" @click="likePost(data, index, 'support')">
                          <b-img :src="require('@/assets/images/icons/like/hand-heart.png')
                            " width="24" title="Support" class="mr-50"></b-img>
                        </div>
                        <div v-if="data.likeType == 'idea'" @click="likePost(data, index, 'idea')">
                          <b-img :src="require('@/assets/images/icons/like/lightbulb.png')
                            " width="24" title="Insightful" class="mr-50 active-idea"></b-img>
                        </div>
                        <div v-if="data.likeType == 'smile'" @click="likePost(data, index, 'smile')">
                          <b-img :src="require('@/assets/images/icons/like/smile.png')" width="24" title="Funny"
                            class="mr-50"></b-img>
                        </div>

                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(data, index, 'like')">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Like"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'clap')">
                            <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'love')">
                            <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Love"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'support')">
                            <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Support"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'idea')">
                            <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(data, index, 'smile')">
                            <b-img :src="require('@/assets/images/icons/like/smile.svg')
                              " width="32" v-b-tooltip.hover.v-default title="Funny"></b-img>
                          </div>
                        </div>
                      </div>
                      <span class="text-primary" v-if="data.likes_count" v-b-modal="'show-likes-' + data.hashid">{{
                        kFormatter(data.likes_count) }}</span>
                    </b-link>
                    <div class="d-none d-lg-flex mobile-avatar align-items-center">
                      <!-- <b-avatar-group size="24" class="ml-50">
                      <div class="d-flex reaction-details"  v-for="(like, i) in getMosReactions(postLikes)">
                        <b-img v-if="like == 'love'" :src="require('@/assets/images/icons/like/love-emoji.png')" width="24" height="24"/>
                        <b-img v-if="like == 'idea'" :src="require('@/assets/images/icons/like/idea-emoji.png')" width="24" height="24"/>
                        <b-img v-if="like == 'clap'" :src="require('@/assets/images/icons/like/clap-emoji.png')" width="24" height="24"/>
                        <b-img v-if="like == 'like'" :src="require('@/assets/images/icons/like/thumb-emoji.png')" width="24" height="24"/>
                        <b-img v-if="like == 'support'" :src="require('@/assets/images/icons/like/handlove-emji.png')" width="24" height="24"/>
                        <b-img v-if="like == 'smile'" :src="require('@/assets/images/icons/like/smile-emji.png')" width="24" height="24"/>
                      </div>
                      <span class="pl-25"></span>
                      <b-avatar
                        v-for="(like, i) in postLikes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.bottom="like.user.name"
                        class="pull-up m-0"
                        :src="like.user.avatar"
                      />
                    </b-avatar-group> 
                    <b-link
                      v-if="data.likes_count > 3"
                      class="text-primary text-nowrap ml-25"
                      v-b-modal="'show-likes-' + data.hashid"
                    >
                      +{{ data.likes_count - 3 }} more
                    </b-link> -->
                    </div>
                    <!-- <div class="d-flex d-lg-none mobile-avatar align-items-center">
                    <b-avatar-group size="24" class="ml-50">
                      <b-avatar
                        v-for="(like, i) in postLikes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.bottom="like.user.name"
                        class="pull-up m-0"
                        :src="like.user.avatar"
                      />
                    </b-avatar-group>
                    <b-link
                      v-if="data.likes_count > 3"
                      class="text-primary text-nowrap ml-25"
                      v-b-modal="'show-likes-' + data.hashid"
                    >
                      +{{ data.likes_count - 3 }} more
                    </b-link>
                  </div> -->
                  </b-col>
                  <b-modal :id="'show-likes-' + data.hashid" hide-footer modal-class="modal-primary modal-likes"
                    scrollable title="Reactions" class="text-danger" @show="getPostReactions(data.hashid)">
                    <b-list-group v-if="postLikes && postLikes.length">
                      <b-list-group-item v-for="like in postLikes" :key="'user-' + like.user.hashid"
                        class="d-flex align-items-center">
                        <b-avatar :src="like.user.avatar" size="24" class="mr-1" />
                        {{ like.user.name }}
                        <div class="ml-auto" v-if="like.type == 'like'">
                          <b-img :src="require('@/assets/images/icons/like/like.png')" width="24" title="Like"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'clap'">
                          <b-img :src="require('@/assets/images/icons/like/clapping.png')
                            " width="24" title="Celebrate"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'love'">
                          <b-img :src="require('@/assets/images/icons/like/love-icon.png')
                            " width="24" title="Love"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'support'">
                          <b-img :src="require('@/assets/images/icons/like/hand-heart.png')
                            " width="24" title="Support"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'idea'">
                          <b-img :src="require('@/assets/images/icons/like/lightbulb.png')
                            " width="24" title="Insightful"></b-img>
                        </div>
                        <div class="ml-auto" v-if="like.type == 'smile'">
                          <b-img :src="require('@/assets/images/icons/like/smile.png')" width="24" title="Funny"></b-img>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <b-card-text v-else-if="postLikes && postLikes.length == 0">
                      <div class="item error text-center my-2 empty-data">
                        <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                          style="background-color: transparent" />
                        <h4> OH NO!</h4>
                        <p class="d-block mb-1">No Records Found!</p>
                      </div>
                    </b-card-text>
                    <b-card-text v-else-if="postLikes == null">
                      <div class="d-flex align-items-center">
                        <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                          style="background-color: transparent" />
                        <h4> Loading </h4>
                      </div>
                    </b-card-text>
                  </b-modal>
                  <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons">
                    <!-- <b-link v-b-toggle.add-sidebar-view @click="viewPost(data)"
                      class="text-body text-nowrap d-flex align-items-center">
                      <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-50 text-primary"
                        v-b-tooltip.hover.v-default title="Comments" />
                      <span v-if="data.comments_count" class="text-muted mr-50">{{
                        kFormatter(data.comments_count)
                      }}</span>
                    </b-link>
                    <b-link v-if="data.visit_count_total" class="text-body text-nowrap d-flex align-items-center"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-50 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted mr-50">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                    </b-link> -->
                    <b-link
                  v-if="data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  @click="viewPost(data)"
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(data.comments_count) }}</span>
                </b-link>
                <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                />
                <b-link v-if="data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-2"
                      @click="showVisits(data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                />

                    <b-modal :id="'show-visits-' + data.hashid" hide-footer modal-class="modal-primary" scrollable
                      title="Views" class="text-danger">
                      <b-list-group v-if="postViews && postViews.length">
                        <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid"
                          class="d-flex align-items-center">
                          <b-avatar :src="user.avatar" size="24" class="mr-1" />
                          {{ user.name }}
                        </b-list-group-item>
                      </b-list-group>
                      <b-card-text v-else-if="isBusy">
                        <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                            style="background-color: transparent" />
                          <h4> Loading </h4>
                        </div>
                      </b-card-text>
                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                            style="background-color: transparent" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-modal>
                    <b-link class="text-body text-nowrap d-flex align-items-center" @click="savePost(data, index)">
                      <feather-icon icon="BookmarkIcon" class="mr-25 text-primary"
                        :class="data.youSaved ? 'saved-post' : 'profile-icon'" v-b-tooltip.hover.v-default
                        :title="data.youSaved ? 'Remove from Saved' : 'Save Post'" size="20" />
                      <span class="text-muted mr-50">{{
                        kFormatter(data.visit_count_total)
                      }}</span>
                    </b-link>
                  </b-col>
                </b-row>
                <!--/ likes comments  share-->
              </b-card-body>
              <b-card-footer class="p-0">
                <div class="comment-details">
                  <comments :post="data" :index="index" :employeeListOptions.sync="employeeListOptions" />

                  <input type="text" v-show="false" v-model="userSearch" />

                  <div id="editor-container"></div>
                  <div class="list-group" style="max-height: 200px; overflow-y: scroll">
                    <div class="item fruit list-group-item list-group-item-action br-0" v-for="user in filteredList()"
                      :key="user.code">
                      <p class="mb-0" @click="addTag(user.label, 'comment-field-' + index)">
                        {{ user.label }}
                      </p>
                    </div>
                    <div class="item error text-center my-2 empty-data" v-if="userSearch && !filteredList().length">
                      <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="150" class="mr-50"
                        style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No results found!</p>
                    </div>
                  </div>
                </div>
              </b-card-footer>
            </b-card>

            <b-col cols="12" order="4">
              <div class="text-center mb-2 d-flex align-items-center justify-content-center">
                <div v-if="isPostLoading" class="text-center my-5 empty-data">
                  <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50"
                    style="background-color: transparent" />
                  <h4> Loading </h4>
                  <p class="d-block mb-1">Please Wait</p>
                </div>
                <b-col cols="12" order="4" v-else>
                  <div v-if="posts.length == 0" class="text-center my-5 empty-data">
                    <b-img :src="require('@/assets/images/empty-data/no-result.svg')" width="100" class="mr-50"
                      style="background-color: transparent" />
                    <h4> OH NO!</h4>
                    <p class="d-block mb-1">No results found!</p>
                  </div>
                </b-col>
              </div>
            </b-col>
          </div>

          <EventsList v-if="filterByType == 'event'" />
          <PollsList v-if="filterByType == 'poll'" />
        </div>
      </div>

      <div class="col-lg-4 col-xl-4" v-if="!$route.params.id">
        <div class="eventpoll-highlight">
          <div class="event-data" v-if="filterByType != 'event'">
            <h4 class="mb-1">Recent Events</h4>
            <b-card class="p-0">
              <EventsShort :recent-events="recentEvents" />
            </b-card>
          </div>
          <div class="poll-data" v-if="filterByType != 'poll'">
            <h4 class="mb-1">Recent Polls</h4>
            <b-card>
              <PollsShort :recent-polls="recentPolls" />
            </b-card>
          </div>
        </div>
        <!-- events and polls goes here -->
        <Timeline v-if="userData.company_twitter" :id="userData.company_twitter" source-type="profile" :options="{
          tweetLimit: '5',
          theme: $store.state.appConfig.layout.skin,
        }" class="mb-2 tweets">
        </Timeline>
        <YouTubeWidget v-if="userData.company_youtube == 'true'" :apiKey="userData.company_youtube_apikey"
          :channelId="userData.company_youtube_channel_id" :resultsPerRequest="3"></YouTubeWidget>
      </div>
      <div v-if="$route.params.id && userData.application_mode == 'company'" class="col-lg-4 col-xl-4">
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-about :about-data="profileData" />
        </b-overlay>
        <b-overlay :show="isLoading" rounded="sm" :variant="skin">
          <profile-upcoming-events v-if="profileData.events.length" :events="profileData.events" />
        </b-overlay>
        <b-overlay v-if="$route.params.id" :show="isLoading" rounded="sm" :variant="skin">
          <profile-suggested-pages v-if="profileData.teamMates.length" :pages-data="profileData.teamMates" />
        </b-overlay>
      </div>
    </div>
    <b-modal v-if="communityChannels.length" v-for="channel in communityChannels" :key="channel.value"
      :id="'unseen-' + channel.value" modal-class="modal-primary modal-likes" scrollable size="lg"
      :title="'Unseen ' + channel.label + ' Posts'" ok-title="Mark all as Read" ok-only @ok="markAsRead(channel.value)"
      class="text-danger">
      <b-list-group v-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length
        ">
        <b-list-group-item :key="post.hashid" v-for="post in unseenBadge['unseen_' + channel.value]">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25 text-primary" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge['unseen_' + channel.value] &&
        unseenBadge['unseen_' + channel.value].length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal id="unseen-feeds" modal-class="modal-primary modal-likes" scrollable size="lg" title="Unseen Feeds"
      class="text-danger" ok-title="Mark all as Read" ok-only @ok="markAsRead('feeds')">
      <b-list-group v-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length">
        <b-list-group-item v-for="post in unseenBadge.unseen_feeds" :key="post.hashid">
          <b-row>
            <b-col md="1" sm="2" class="unseen-col">
              <b-avatar :src="post.avatar" style="background: transparent" size="24" />
            </b-col>
            <b-col md="7" sm="8" class="unseen-col"> "{{ post.title }}" </b-col>
            <b-col cols="3" class="d-none d-md-block">
              -by {{ post.author }}
            </b-col>
            <b-col cols="1">
              <b-link class="text-body" @click="OpenPost(post)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="mr-25 text-primary" v-b-tooltip.hover.v-default
                  v-b-tooltip.left title="Open Post" />
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-text v-else-if="unseenBadge.unseen_feeds && unseenBadge.unseen_feeds.length == 0
        ">
        <div class="item error text-center my-2 empty-data">
          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
            style="background-color: transparent" />
          <h4> OH NO!</h4>
          <p class="d-block mb-1">No Records Found!</p>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Replies from "./Replies.vue";
import Comments from "./Comments.vue";
import PostView from "./PostView.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import { Timeline } from "vue-tweet-embed";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import VueTimepicker from "vue2-timepicker/src";

import QuillInput from "@/components/QuillInput.vue";
import PostEdit from "./PostEdit.vue";
import EventAdd from "@/views/feed/events/EventAdd.vue";
import EventsList from "@/views/feed/events/EventsList.vue";
import PollAdd from "@/views/feed/polls/PollAdd.vue";
import PollsList from "@/views/feed/polls/PollsList.vue";
import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import Vue from "vue";
import { bus } from "@/main.js";
import YouTubeWidget from "vue-youtube-widget";
Vue.use(infiniteScroll);
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
import { VueAutosuggest } from "vue-autosuggest";
import axios from "@axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import ProfileSuggestedPages from "./ProfileSuggestedPages.vue";
import ProfileAbout from "./ProfileAbout.vue";
import ProfileUpcomingEvents from "./ProfileUpcomingEvents.vue";
import EventsShort from "@/views/feed/events/EventsShort.vue";
import PollsShort from "@/views/feed/polls/PollsShort.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    Replies,
    PostView,
    QuillInput,
    PostEdit,
    EventAdd,
    EventsList,
    EventsShort,
    PollAdd,
    PollsList,
    PollsShort,
    YouTubeWidget,
    Timeline,
    VueAutosuggest,
    // Navbar Components
    BCarousel,
    BCarouselSlide,
    Comments,
    ProfileSuggestedPages,
    ProfileAbout,
    ProfileUpcomingEvents,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
    customFields: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    headerData: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    infiniteScroll,
  },

  data() {
    return {
      coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
      userData: this.$cookies.get("userData"),
      options: [
        {
          text: "Energetic",
          value: "5",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/cute.svg"),
        },
        {
          text: "Focused",
          value: "4",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/shy.svg"),
        },
        {
          text: "Neutral",
          value: "3",
          color: "btn-outline-primary",
          icon: "PauseIcon",
          img: require("@/assets/images/moods/neutral.svg"),
        },
        {
          text: "Stressed",
          value: "2",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/thinking.svg"),
        },
        {
          text: "Frustrated",
          value: "1",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/angry.svg"),
        },
      ],
      swipeData: {
        type: "",
        user_feeling: 6,
      },
      recentSwipes: null,
      mood_options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
      myFiles: [],
      Selected: '',
      skin: store.state.appConfig.layout.skin,
      posts: [],
      filterByType: "community",
      comment_id: null,
      comment_post_index: null,
      reply_to: null,
      skin: store.state.appConfig.layout.skin,
      sidebarViewActive: false,
      sidebarEditActive: false,
      sidebarEventAddActive: false,
      sidebarPollAddActive: false,
      postId: null,
      postData: null,
      userData: this.$cookies.get("userData"),
      search: "",
      userSearch: "",
      userSearchActive: false,
      inputId: null,
      isBusy: true,
      isPostLoading: true,
      tempFlag: true,
      lastPage: 1,
      nextPage: 1,
      currentPage: 1,
      postLikes: null,
      searchQuery: "",
      searchQueryEvent: "",
      searchQueryPoll: "",
      editComments: [],
      onGoingProgram: {},
      typeFilter: "upcoming",
      typeChannel: "Select A Channel",
      typeOptions: [
        { value: "upcoming", label: "Upcoming" },
        { value: "past", label: "Past" },
      ],
      timeOptions: [
        { value: "Limited", label: "Limited" },
        { value: "UnLimited", label: "UnLimited" },
      ],
      postTypeOptions: [
        { value: "text-post", label: "New Text Post" },
        { value: "video-post", label: "New Video Post" },
        { value: "photo-post", label: "New Photo Post" },
        { value: "quote-post", label: "New Quote Post" },
      ],
      postType: 'text-post',
      page2: false,
      
      QuoteSourceTypes: [
        { value: "Book", label: "Book" },
        { value: "Blog/Article", label: "Blog/Article" },
        { value: "Video", label: "Video" },
        { value: "Podcast", label: "Podcast" },
      ],
      selectChannel: [
        { value: "Community", label: "Community" },
        { value: "Feedback Box", label: "Feedback Box" },
        { value: "DCKAP Reads", label: "DCKAP Reads" },
        { value: "Feeds", label: "Feeds" },
        { value: "Events", label: "Events" },
        { value: "Polls", label: "Polls" },
      ],
      unseenBadge: {
        community: 0,
        feeds: 0,
        events: 0,
        polls: 0,
        feedback: 0,
      },
      postViews: [],
      datasuggest: {
        data: [
          {
            developers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
            designers: [
              {
                name: "guna",
                time: "",
                img: "",
              },
            ],
          },
        ],
      },
      filteredOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "Type 'e'",
      },
      limit: 10,
      communityChannels: [
        { label: "Community", value: "community" },
        { label: "Feedback Box", value: "feedback_box" },
      ],
      profileData: {
        header: [],
        userAbout: [],
        teamMates: [],
        events: [],
      },
      isLoading: false,
      postsData: [],
      recentPolls: [],
      recentEvents: [],
      pollData: {
        title: "",
        description: "",
        options: ["", ""],
        allow_multiple: false,
        voter_identity_type: "identified",
        limited_time: false,
      }
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  beforeCreate() {
    axios.get("communityCollection").then((response) => {
      this.recentPolls = response.data.data.recentPolls;
      this.recentEvents = response.data.data.recentEvents;
    });
  },
  computed: {
    pinnedPosts() {
      if (this.posts && this.posts.length) {
        var pinPosts = [];
        this.posts.forEach((post) => {
          if (post.is_pinned == "true") {
            pinPosts.push(post);
          }
        });
        const chunkSize = 2;
        var data = [];
        for (let i = 0; i < pinPosts.length; i += chunkSize) {
          const chunk = pinPosts.slice(i, i + chunkSize);
          data.push(chunk);
        }
        return data;
      }
      return [];
    },
  },
  mounted() {
    axios.get("/user").then((response) => {
      const userData = response.data.data;
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      );
    });

    if (this.userData.previous_day_attendance_status.NO &&
      (!this.$cookies.get("lastday_status_shown") ||
        this.$cookies.get("lastday_status_shown") != 1)
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "You have not fulfilled min hours required for " +
              this.userData.previous_day_attendance_status.No,
            icon: "AlertTriangleIcon",
            variant: "warning",
            text: "Regularization Required for last working day, Please Apply for Regularization for last working day",
          },
        },
        {
          timeout: 6000,
        }
      );
      this.$cookies.set("lastday_status_shown", 1, 60 * process.env.VUE_APP_LOGIN_TIMEOUT);
    }
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 3000) {
        $(".tweets").addClass("active-sticky");
      } else {
        $(".tweets").removeClass("active-sticky");
      }
    });
    if (this.$route.query.post_hash_id) {
      this.postId = this.$route.query.post_hash_id;
    }
  },
  created() {
    bus.$on("loadPosts", (params = "") => {
      this.searchQuery = params;
      this.getPosts();
    });
    this.fetchChannelList();
    this.filterByType = this.communityChannels[0].value;

    this.getPosts();
    this.getOnGoingProgram();
    if (this.$route.params.id) {
      this.fetch_user_data(this.$route.params.id);
    }
  },
  watch: {
    sidebarEditActive(val) {
      if (!val) {
        this.getPosts();
      }
    },
    sidebarEventAddActive(val) {
      if (!val) {
        this.getPosts();
      }
    },
    sidebarPollAddActive(val) {
      if (!val) {
        this.getPosts();
      }
    },
    filterByType(val) {
      this.getPosts();
    },
    // searchQuery(val) {
    //   if (val.length > 3 || val.length == 0) {
    //     this.getPosts();
    //   }
    // },
    // searchQueryEvent(val) {
    //   if (val.length > 3 || val.length == 0) {
    //     bus.$emit("loadEvents", { q: this.searchQueryEvent, type: val });
    //   }
    // },
    typeFilter(val) {
      bus.$emit("loadEvents", { q: this.searchQueryEvent, type: val });
    },
    // searchQueryPoll(val) {
    //   if (val.length > 3 || val.length == 0) {
    //     bus.$emit("loadPolls", val);
    //   }
    // },
  },
  methods: {
    checkin() {
      this.swipeData.type = "In";
      this.swipeData.browser_agent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.swipeData.device_type = deviceType;
      axios.post("/swipe/add", this.swipeData).then((response) => {
        this.userData.last_swipe_type = "In";
        this.$cookies.set(
          "userData",
          this.userData,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.$nextTick(() => {
          this.$bvModal.hide("modal-1");
        });
        // this.$toast.success(response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Check In",
            icon: "ToggleRightIcon",
            text: response.data.message,
            variant: "success",
          },
        });
      });
    },
    checkout() {
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';

      axios
        .post("/swipe/add", {
          type: "Out",
          user_feeling: this.swipeData.user_feeling,
          browser_agent: navigator.userAgent,
          device_type: deviceType,
        })
        .then((response) => {
          this.userData.last_swipe_type = "Out";
          this.$cookies.set(
            "userData",
            this.userData,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
          this.$nextTick(() => {
            this.$bvModal.hide("modal-2");
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Check Out",
              icon: "ToggleLeftIcon",
              text: response.data.message,
              variant: "danger",
            },
          });
        });
    },
    resetModal() {
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.checkin();
    },
    getRecentSwipes() {
      axios.get("/swipes").then((response) => {
        if (response.data.success) {
          this.recentSwipes = response.data.data;
        } else {
          this.$toast.error(response.data.message);
        }
      });
    },
    showVisits(postId) {

      this.postViews = null;
      var modal = "show-visits-" + postId;
      this.$bvModal.show(modal);
      let data = {};
      data.url = "/post-visits/" + postId;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.postViews = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
          this.$bvModal.hide(modal);
        });
    },
    visibilityChanged(isVisible, entry, customArgument) {
      if (isVisible) {
        this.isVisible = isVisible;
        this.getPostData(entry.target.id);
      }
    },
    getPostData(itemId) {
      if (!itemId) {
        return true;
      }

      let data = {};
      data.url = "/posts/" + itemId;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.post_data = res.data.data;
          this.isBusy = false;
          if (res.data.channels) {
            this.unseenBadge = res.data.channels;
          }
          for (let index = 0; index < this.posts.length; index++) {
            var post = this.posts[index];
            if (post.hashid == this.post_data.hashid) {
              this.posts[index] = this.post_data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOnGoingProgram() {

      let data = {};
      data.url = "on-going-secret-santa";
      this.$store.dispatch("app/get", data).then((response) => {
        if (response.data.success) {
          this.onGoingProgram = response.data.data;
        }
      });
    },
    shareUrl(hash_id) {
      return window.location.origin + "?post_hash_id=" + hash_id;
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    OpenPost(post) {
      return window.open("?post_hash_id=" + post.id, "_blank");
    },
    wordCount(text) {
      const wordsArr = text.trim().split(" ");
      return wordsArr.filter((word) => word !== "").length;
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    refreshListData() {
      if (this.filterByType == "event") {
        bus.$emit("loadEvents", { q: this.searchQueryEvent, type: this.typeFilter });
      } else if (this.filterByType == "poll") {
        bus.$emit("loadPolls", "");
      } else {
        this.getPosts();
      }
    },
    loadMore() {
      this.tempFlag = false;
      if (this.currentPage < this.lastPage) {
        this.isPostLoading = true;
        let params = {};
        params = {
          page: this.nextPage,
          channel_type: this.filterByType,
          q: this.searchQuery,
          createdBy: this.$route.params.id,
        };
        this.$http
          // .get(`/posts?page=${this.nextPage}&channel_type=${this.filterByType}`)
          .get(`/posts`, { params: params })
          .then((res) => {
            this.posts = this.posts.concat(res.data.data);

            const ids = this.posts.map((o) => o.hashid);
            const filtered = this.posts.filter(
              ({ hashid }, index) => !ids.includes(hashid, index + 1)
            );
            this.posts = filtered;

            if (res.data.meta.current_page < res.data.meta.last_page) {
              this.nextPage = res.data.meta.current_page + 1;
            }
            this.lastPage = res.data.meta.last_page;
            this.currentPage = res.data.meta.current_page;
            this.isPostLoading = true;
          })
          .catch((error) => {
            this.isPostLoading = true;
          });
      }

    },
    AddFilter(type) {
      this.filterByType = type;
      var nonChannels = ['poll', 'event', 'post']
      if (!nonChannels.includes(type)) {
        this.$emit('updateChannel', type);
      }

    },
    editComment(comment, post_id, index) {

      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.updatePostArray(res.data.data, index);
        })
        .catch((error) => {
          console.log(error);
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    getPosts() {

      this.isPostLoading = true;

      let data = {};
      data.url = "posts";
      data.params = {
        channel_type: this.filterByType,
        q: this.searchQuery,
        createdBy: this.$route.params.id,
      };
      this.$store.dispatch("app/get", data).then((res) => {
        this.posts = res.data.data;
        this.isPostLoading = false;
        this.nextPage = res.data.meta.current_page + 1;
        this.lastPage = res.data.meta.last_page;
        this.currentPage = res.data.meta.current_page;
        this.unseenBadge = res.data.unseen_post_count;
      });
    },
    filteredList() {
      if (!this.userSearch.length) {
        return [];
      }
      return this.employeeListOptions.filter((user) =>
        user.label.toLowerCase().includes(this.userSearch.toLowerCase())
      );
    },

    fetchChannelList() {

      let data = {};
      data.url = "list/community-channels";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.communityChannels = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    getPostReactions(postID) {
      this.postLikes = null;
      this.$http.get(`/posts/${postID}/likes`).then((res) => {
        this.postLikes = res.data.data;
      });
    },
    append(emoji) {
      var data = document.getElementById(this.inputId).value;
      alert(data);
      var lastTag = data.substring(data.length - 4, data.length);
      if (lastTag == "</p>") {
        data = data.substring(0, data.length - 4) + emoji + lastTag;
        document.getElementById(this.inputId).value = data;
      } else {
        document.getElementById(this.inputId).value += emoji;
      }
    },
    setInputId(inputId) {
      this.inputId = inputId;
    },
    kFormatter,
    viewPost(postData) {
      this.postId = postData.hashid;
      this.postData = postData;

      //this.sidebarViewActive = true;
    },
    addReply(data, index) {

      this.comment_id = data.hashid;
      this.reply_to = data.user.name;
      this.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {

      this.comment_id = null;
      this.reply_to = null;
    },
    like(comment_id, index) {

      let data = {};
      data.url = "comments/" + comment_id + "/like";
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "liking failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        });
    },
    addComment(data, index) {

      const comment = document.getElementById(`comment-field-${index}`).value;
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Please enter the comments",
            variant: "danger",
          },
        });
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/posts/${data.hashid}/comments`, params)
        .then((res) => {
          this.reply_to = null;
          this.comment_id = null;
          this.updatePostArray(res.data.data, index);
          document.getElementById(`comment-field-${index}`).value = "";
          $(".ql-editor").html("");
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    likePost(data, index, type) {
      if (data.youLiked && data.likeType == type) {
        this.$http.delete(`/posts/${data.hashid}/like`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      } else {
        this.$http.post(`/posts/${data.hashid}/like/${type}`).then((res) => {
          this.updatePostArray(res.data.data, index);
        });
      }
    },
    savePost(data, index) {
      if (data.youSaved) {
        this.$http.delete(`/saved-models/${data.hashid}/unsave`).then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post removed from saved posts",
              icon: "XCircleIcon",
              variant: "danger",
            },
          });
        });
      } else {
        this.$http.post(`/saved-models/${data.hashid}/save`).then((res) => {
          this.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post added to saved posts",
              icon: "CheckCircleIcon",
              variant: "primary",
            },
          });
        });
      }
    },
    updatePostArray(data, index) {
      this.$set(this.posts, index, data);
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {

      let data = {};
      data.url = "posts/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            document.getElementById("post-card-" + id).remove();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Post Deleted Successfully.",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Post Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Deletion failed.1",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    onSelected(option) {
      this.selected = option.item;
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.data[0].developers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredDesigner = this.datasuggest.data[0].designers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
      const filteredData = filteredDevelopers.concat(filteredDesigner);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },

    pinPost(data) {
      axios.get("/pin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Pinned",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to pin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    unpinPost(data) {
      axios.get("/unpin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Un-Pined",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to unpin post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    getMosReactions(likes) {
      var reactions = {
        like: 0,
        love: 0,
        support: 0,
        clap: 0,
        smile: 0,
        idea: 0,
      };

      likes.forEach((like) => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter((key) => reactions[key] !== 0);

      return top3;
    },
    approvePost(data) {
      axios.get("post/approve/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Approved",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Approve post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    rejectPost(data) {
      axios.get("post/reject/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Reject",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to Reject post",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    markAsRead(type) {
      axios.get("/markAsRead/" + type).then((response) => {
        if (response.data.success) {
          this.getPosts();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Posts marked as read",
              icon: "UserCheckIcon",
              variant: "primary",
              text: response.data.message,
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Unable to mark posts as read",
              icon: "UserXIcon",
              variant: "danger",
              text: response.data.message,
            },
          });
        }
      });
    },
    fetch_user_data(userId) {

      this.isLoading = true;
      let formData = {};
      formData.url = "profile/" + userId;
      this.$store
        .dispatch("app/get", formData)
        .then((res) => {
          this.profileData.header = res.data.data.header;
          this.profileData.userAbout = res.data.data.userAbout;
          this.profileData.teamMates = res.data.data.teamMates;
          this.profileData.events = res.data.data.events;
          this.profileData = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee data failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    addOption() {
      this.pollData.options.push("");
    },
    removeOption(index) {
      this.pollData.options.splice(index, 1);
    },
    
  },
};
</script>
<style>
@import "~vue-youtube-widget/dist/YouTubeWidget.min.css";

.twitter-timeline-rendered iframe {
  width: 100% !important;
}

.zircly-hashtag {
  color: green;
}

span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}

.mood-btn:hover {
  scale: 1.3;
  background: transparent;
  opacity: 1;
}

.mood-btn {
  scale: 0.9;
  background: transparent;
  opacity: 0.7;
  transition: all 0.3s;
}

.mood-btn.active {
  scale: 1.2;
  background: transparent;
  background-color: transparent !important;
  opacity: 1;
}
</style>
